<template>
  <section class="recommended-products container pt-5 mt-2 mt-sm-5 mb-sm-5 mb-4">
    <BigTitle class="mb-2 mb-sm-4" :title="`محصولات ${$root.siteTitle}`" />
    <div class="d-flex flex-wrap justify-content-center align-items-baseline">
      <div class="recommended-products-category d-flex">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <!-- <li class="nav-item ml-1" role="presentation">
          <li v-if="mostDiscount.length" class="nav-item ml-1 ml-sm-4 " role="presentation">
            <a
                class="nav-link py-1 px-md-3 px-2 fontsize-medium bg-none text-color-444 "
                id="home-tab"
                data-toggle="tab"
                href="#discount"
                role="tab"
                aria-controls="discount"
                aria-selected="true"
            >تخفیف دار ها</a
            >
          </li>
          <li class="nav-item ml-1" role="presentation">
            <a
              class="nav-link py-1 px-md-3 px-2 fontsize-medium bg-none text-color-444 active"
              id="profile-tab"
              data-toggle="tab"
              href="#newest"
              role="tab"
              aria-controls="newest"
              aria-selected="false"
              >جدیدترین</a
            >
          </li> -->
          <li class="nav-item ml-1" role="presentation">
            <a
              class="nav-link py-1 px-md-3 px-2 fontsize-medium bg-none text-color-444 active"
              id="contact-tab"
              data-toggle="tab"
              href="#contact"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
              >پر فروش ترین</a
            >
          </li>
          
        </ul>
      </div>
    </div>
    <div class="recommended-content tab-content mt-5" id="myTabContent">
      <!-- <div

        class=" recommended-content-item tab-pane fade show active"
        id="newest"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div class="recommended-content-grid">
          <Product
            v-for="(product, index) in suggestions"
            :key="'suggestions-' + index"
            :product="product"
          ></Product>
        </div>
        <div class="text-center">
          <MoreButton to="/products?sort=newest"/>
        </div>
      </div> -->
      <div
        class=" recommended-content-item tab-pane fade show active"
        id="contact"
        role="tabpanel"
        aria-labelledby="contact-tab"
      >
        <div class="recommended-content-grid">
          <Product
            v-for="(product, index) in mostSales"
            :key="'mostSales' + index"
            :product="product"
          ></Product>
        </div>
        <div class="text-center">
          <MoreButton to="/products?sort=top_sales"/>
        </div>
      </div>
      <div
          class="recommended-content-item tab-pane fade show"
          id="discount"
          role="tabpanel"
          aria-labelledby="discount-tab"
      >
        <div class="recommended-content-grid">
          <Product
              v-for="(product, index) in mostDiscount"
              :key="index"
              :product="product"
          ></Product>
        </div>

        <div class="text-center">
          <MoreButton to="/products?sort=most_discount"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Product from "@/parts/Front/components/product/Product";
import MoreButton from "@/parts/Front/components/shared/MoreButton";
import BigTitle from "@/parts/Front/nicecomponents/shared/BigTitle";

export default {
  name: "RecommendedProduct",
  components: {
    BigTitle,
    MoreButton,
    Product,
  },
  inject: ['mediaQueries'],
  computed: {
    showCount() {
      if (this.mediaQueries.xxxLarge) {
        return 8
      }
      if (this.mediaQueries.xxLarge) {
        return 8;
      }
      if (this.mediaQueries.xLarge) {
        return 8;
      }
      if (this.mediaQueries.medium) {
        return 6
      }
      return 6
    },
    suggestions() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return Array(this.showCount).fill(null);
      } else {
        return this.getEnough(this.$store.getters["front/getHomeData"].suggestions)
      }
    },
    mostSales() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return Array(this.showCount).fill(null);
      } else {
        return this.getEnough(this.$store.getters["front/getHomeData"].mostSales);
      }
    },
    mostDiscount() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return [];
      } else {
        return this.getEnough(this.$store.getters["front/getHomeData"].mostDiscount);
      }
    },
    new_products() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return Array(this.showCount).fill(null);
      } else {
        return this.getEnough(this.$store.getters["front/getHomeData"].new_products);
      }
    },
  },
  methods: {
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
    getEnough(array) {
      return [...array].splice(0, Math.min(array.length, this.showCount));
    }
  }
};
</script>

<style scoped>
@media screen and(max-width: 500px) {
  .recommended-products-category a.fontsize-medium {
    font-size: 13px !important;
  }
}
</style>
