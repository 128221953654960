<template>
  <section v-if="hasAnyBanners" class="banners d-flex justify-content-between container banner my-5">
    <router-link
      v-for="(banner, index) in banners"
      :key="index"
      :to="
        banner
          ? banner.linkable_type != null
            ? findUrl(banner.linkable_type, banner.linkable_id)
            : banner.link
          : ''
      "
      :target="banner && banner.new_tab == 1 ? '_blank' : ''"
      class="overflow-hidden"
    >
      <img loading="lazy" v-if="banner" :src="banner.picture" class="w-100" alt="" />
    </router-link>
  </section>
</template>

<script>
import findUrl from "@/libraries/links/LinkResolver";
export default {
  name: "Banners",
  props: {
    banners: Array,
  },
  methods: {
    findUrl,
  },
  computed: {
    hasAnyBanners() {
      let ok = false;
      this.banners.every(banner => {
        if (banner) {
          ok = true
          return false;
        }
        return true
      })
      return ok;
    }
  }
};
</script>

<style scoped>
.banners > a {
  /* max-height: 209px; */
}
 </style>
