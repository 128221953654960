<template>
  <section class="about-home container">
    <div>
      <img style="max-width: 330px" :src="require('@/assets/images/home-banner.png')"  />
    </div>
    <div>
      <AboutHomeItem v-for="item in items" :item="item"/>
    </div>
  </section>
</template>

<script>
import AboutHomeItem from "@/parts/Front/components/home/aboutHome/AboutHomeItem";
export default {
  name: "AboutHome",
  components: {AboutHomeItem},
  data() {
    return {
      items: [
        {
          title: 'فروش لوازم و تجهیزات آتلیه',
          description: 'فروش لوازم و تجهیزات آتلیه اعم از پایه های ثابت و متحرک، هلی شات و پرده سبز و ...',
          image: require('@/assets/images/other/l1.png')
        },
        {
          title: 'فروش لوازم و تجهیزات آتلیه',
          description: 'فروش لوازم و تجهیزات آتلیه اعم از پایه های ثابت و متحرک، هلی شات و پرده سبز و ...',
          image: require('@/assets/images/other/l2.png')
        },
        {
          title: 'فروش لوازم و تجهیزات آتلیه',
          description: 'فروش لوازم و تجهیزات آتلیه اعم از پایه های ثابت و متحرک، هلی شات و پرده سبز و ...',
          image: require('@/assets/images/other/l3.png')
        },
        {
          title: 'فروش لوازم و تجهیزات آتلیه',
          description: 'فروش لوازم و تجهیزات آتلیه اعم از پایه های ثابت و متحرک، هلی شات و پرده سبز و ...',
          image: require('@/assets/images/other/l4.png')
        }
      ]
    }
  }
}
</script>

<style scoped>
.about-home {
  display: flex;
}

.about-home > div {
  margin: 0 20px;
}

.about-home > div:first-child {
  margin-right: 0;
}

.about-home > div:last-child {
  margin-left: 0;
}

.about-home > div:nth-child(2){
  display: grid;
  grid-template: repeat(2, 1fr) /  repeat(2, 1fr);
  align-items: center;
}

@media screen and (max-width: 1350px) {
  .about-home > div:first-child {
    display: none;
  }
  .about-home {
    flex-direction: column;
  }

  .about-home > div:nth-child(2){
    row-gap: 15px;
  }
}

@media screen and (max-width: 768px) {
  .about-home > div:nth-child(2){
    display: grid;
    grid-template: repeat(2, 4fr) / 1fr;
    margin: 0;
  }
}



</style>
