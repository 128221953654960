<template>
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      :fill="fill"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"
        :fill="fill"
      />
    </svg>
  </template>
  <script>
  export default {
    props: {
      fill: {
        type: String,
        default: "#ffffff",
      },
    },
  };
  </script>
  