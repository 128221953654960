<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 24 24"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: "#ffffff",
    },
  },
};
</script>
