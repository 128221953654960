<template>
  <div>
    <transition name="fade">
      <div id="showAllCategory" v-show="showAllCategory" class="py-3">
        <h5 id="headerText" class="weight-bold">جستجو دد {{$root.siteTitle}}
        </h5>
        <form id="searchInMobile" @submit.prevent="searchInHavin">
          <input placeholder="محصول مورد نظر را جستوجو کنید" type="text" v-model="search" id="search" />
          <input type="submit" value="جستجو" id="submitSearch" />
        </form>
      </div>
    </transition>
    <div
      @click="closeCategoryList()"
      v-show="showAllCategory"
      id="closeAllCategory"
    ></div>
  </div>
</template>

<script>
export default {
  name: "AllCategory",
  data() {
    return {
      showAllCategory: this.value,
      search: "",
    };
  },
  computed: {
    homeData() {
      let homeData = this.$store.getters["front/getHomeData"];
      return homeData ? homeData : [];
    },
  },
  mounted() {
    window.addEventListener("popstate", this.checkBackButton);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.checkBackButton);
  },
  methods: {
    searchInHavin(){
      this.closeCategoryList();
      this.$router.push("/products?title="+this.search)
    },
    checkBackButton() {
      if (!this.$route.fullPath.includes("#all-category-modal")) {
        this.closeCategoryList();
      }
    },
    closeCategoryList() {
      this.showAllCategory = false;
    
    },
    // تابع زیر برای تعویض ایکن هنگام باز کردن زیر منو است
    changeAngle(id) {
      let el = document.getElementById("angle" + id);
      if (el.classList.contains("fa-angle-down")) {
        el.className = "fa fa-angle-up";
      } else {
        el.className = "fa fa-angle-down";
      }
    },
    close() {
      this.closeCategoryList();
    },
  },
  props: { value: { Boolean } },
  watch: {
    showAllCategory(newVal) {
      this.$emit("input", newVal);
    },
    "$route.path"() {
      this.closeCategoryList();
    },
    value(newVal) {
      this.showAllCategory = newVal;
      if (newVal) {
        this.$router.push(this.$route.fullPath + "#all-category-modal");
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active {
  transition: all 0.2s ease-in;
}

.fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.7, 1, 1);
}

.fade-enter,
.fade-leave-to {
  transform: translatey(10px);
  opacity: 0;
}

#showAllCategory {
  position: fixed;
  top: 20px;
  width: 90%;
  margin-right: 5%;
  z-index: 1000 !important;
  background-color: white;
  margin-left: 5%;
  max-height: 78%;
  overflow: auto;
  box-shadow: 0 9px 20px grey;
  border-radius: 12px;
}

.headingOne {
  margin: 0 !important;
}

#headerText {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  height: 40px;
  /* text-shadow: 1px 3px silver; */
}

#closeAllCategory {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background-color: #00000033;
}

/* با استفاده از کد زیر یک سطر  دسته بندی به محتوا زیر تقسیم میشود  */
.showMoreBtn {
  background-color: transparent !important;
  flex-grow: 11;
  display: flex;
  justify-content: flex-end;
}

.mainRoute {
  flex-grow: 1;
}

#showAllCategory .card {
  background-color: unset;
  border: unset;
}

#showAllCategory .card-header {
  background-color: unset;
  border: unset;
}
.showMoreBtn > button,
#showAllCategory >>> .all-categories-sub-menu button {
  background: transparent;
  border-radius: 50%;
  border: 1px solid #666;
  width: 25px;
  height: 25px;
}

.showMoreBtn > button > i,
#showAllCategory >>> .all-categories-sub-menu button > i {
  transform: translateY(4px);
}
form#searchInMobile #submitSearch {
    width: max-content;
    padding: 10px 15px;
    border-radius: 5px;
    
}

form#searchInMobile #search {
    border-bottom:1px solid #8a8a8a;
    padding-bottom: 5px;
    width: 100%;
}
form#searchInMobile {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 95%;
    margin:50px auto 0;
    align-items: center;
}
</style>
