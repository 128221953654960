<template>
  <div class="category">
    <router-link
      to="/products/category/36?category_id=36"
      class="category__box border-radius20"
    >
      <div class="category__box__image border-radius20">
        <img src="../../../../assets/images/category/mens.png" class="border-radius20" alt="category" />
      </div>
    </router-link>
    <router-link
      to="/products/category/37?category_id=37"
      class="category__box border-radius20"
    >
      <div class="category__box__image border-radius20">
        <img src="../../../../assets/images/category/womens.png" class="border-radius20" alt="category" />
      </div>
    </router-link>
    <router-link
      to="/products/category/38?category_id=38"
      class="category__box border-radius20"
    >
      <div class="category__box__image border-radius20">
        <img src="../../../../assets/images/category/shoes.png" class="border-radius20" alt="category" />
      </div>
    </router-link>
    <router-link
      to="/products/category/39?category_id=39"
      class="category__box border-radius20"
    >
      <div class="category__box__image border-radius20">
        <img src="../../../../assets/images/category/accessory1.png" class="border-radius20" alt="category" />
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "CategoryCard",
  props: {
    item: Object,
    scSize: String,
  },
};
</script>

<style scoped>
/* .category__box .title {
  position: absolute;
  left: -120px;
  top: -80px;
  display: block;
  transform: rotate(-90deg);
  z-index: 2;
  width: 200px;
  height: 20px;
  text-align: left;
  color: #7a7979;
  font-weight: 300;
} */

/* .category__box .title .text {
  transition: 200ms all;
  position: absolute;
  left: -102px;
  bottom: 5px;
  width: 200px;
  height: 20px;
  font-size: 20px;
} */

/* .category__box .title .line {
  height: 130px;
  width: 1px;
  position: absolute;
  left: -39px;
  top: -36px;
  background-color: #a4aaaf;
  transform: rotate(-90deg);

} */
.category {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 40px;
}

.category__box {
  position: relative;
  width: 100%;
  padding: 1rem;
  padding-top: 0;
  flex-grow: 1;
}

.category__box__image {
  flex-grow: 1;
  /* width: 100%; */
  position: relative;
  overflow: hidden;
  /* height: 12em; */
  padding: 10px;

  --transition-duration: 1s;
  --transition-easing: ease;
  transition: transform var(--transition-duration) var(--transition-easing);
  aspect-ratio: 1/1;
}

.category__box__image::before {
  content: "";
  right: -50%;
}

.category__box__image:hover::before {
  background: rgba(255, 255, 255, 0.4);
  width: 25%;
  height: 100%;
  top: 0%;
  transform: translateY(calc(-50% - 2px));
  transform: skew(45deg);
  position: absolute;
  transition: right var(--transition-duration) var(--transition-easing);
  right: 150%;
}

/* .category__box:nth-child(odd):hover img {
  backdrop-filter: blur(2px);
  transform: scale(2)  rotate(25deg);
  overflow: hidden;
}
.category__box:nth-child(even):hover img {
  backdrop-filter: blur(2px);
  transform: scale(2)  rotate(-25deg);
  overflow: hidden;
} */

.category__box__image img {
  box-shadow: 1px 3px 7px #999;
  width: 100%;
  /* height: 100%;
   border-radius: 10px; */
  transition: all 1s ease;
}

@media (max-width: 768px) {
  .category {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin-top: 15px;
  }
}
@media (max-width: 1000px) and (min-width: 520px) {
  .category {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 992px) {
  .category__box__image {
    height: unset;
  }

  /* .category__box .title {
    display: none;
  } */

  /* .category__box:nth-child(odd) {
    margin-top: 0px!important;
  } */

  .category__box {
    /* margin: 10px; */
    width: 90%;
    padding: 5px;
  }
  .category__box {
    width: 100%;
  }
}

@media only screen and (max-width: 430px) {
  .category__box {
    padding: 5px;
  }
}

/* .category__box:hover  .title .text {
  left: -60px!important;
} */

/* .category__box:nth-child(odd) {
  margin-top: 90px;
} */
</style>
