<template>
  <section class="recommended-products container">
    <template v-if="products && products.length">
      <!-- header -->
      <div class="recommended-products-tabs">
        <div
          class="container d-flex justify-content-between align-items-center"
        >
          <h3 class="fontsize-medium text-light m-0">
            <!-- {{ productTitle }} --> 
            <strong>محصولات</strong> شگفت انگیز
          </h3>
          <router-link
            :to="link"
            class="seeMoreHomeLink fontsize-medium text-light"
            >مشاهده همه</router-link
          >
        </div>
      </div>
      <!-- carousel -->
      <div class="recommended-content">
        <div class="recommended-content-item position-relative" id="home">
          <!-- <div :style="`min-height: ${minHeight}px`"> -->
        <div >
            <div
              class="swiper-container"
              v-if="!disabledSliders.includes('recommendedProductSlider')"
            >
              <div class="swiper-wrapper">
                <Product
                  class="swiper-slide"
                  :style="'max-width:' + maxWidth + 'px'"
                  v-for="(product, index) in products"
                  :key="index + 'index'"
                  :product="product"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- buttons -->
      <div class="next-btn-holder d-none d-xl-block">
        <div class="next-btn">
          <div class="next-btn-icon"><RightChevIcon /></div>
        </div>
      </div>
      <div class="prev-btn-holder d-none d-xl-block">
        <div class="prev-btn">
          <div class="prev-btn-icon"><LeftChevIcon /></div>
        </div>
      </div>
    </template>
    <!-- dot loading -->
    <template v-else>
      <Loading :mode="'dot'" />
    </template>
  </section>
</template>

<script>
import Loading from "@/parts/Front/components/shared/Loading";
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";
import debounce from "lodash/debounce";
import LeftChevIcon from "@/parts/Front/components/Icon/LeftChevIcon.vue";
import RightChevIcon from "@/parts/Front/components/Icon/RightChevIcon.vue";

let breakpoints = {
  300: {
    slidesPerView: 1.3,
    spaceBetween: 15,
  },
  500: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  1200: {
    slidesPerView: 4.3,
    spaceBetween: 15,
  },
};

export default {
  name: "AmazingProductBox",
  components: { Product, Loading, LeftChevIcon, RightChevIcon },
  props: {
    productTitle: String,
    link: String,
    products: Array,
  },
  data() {
    return {
      minHeight: "unset",
      maxWidth: "unset",
      onResizeFunction: debounce(this.onResize, 150),
    };
  },
  watch: {
    products: {
      immediate: true,
      handler(products) {
        if (!products) {
          return;
        }
        this.initSlider(true);
      },
    },
  },
  mounted() {
    this.initSlider(false);
    this.setMaxWidth();
    window.addEventListener("resize", this.onResizeFunction);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeFunction);
  },
  computed: {
    showCount() {
      return 16;
    },
  },
  methods: {
    onResize() {
      this.setMaxWidth();
    },
    setMaxWidth() {
      let slidesPerView = this.getSlidesPerView();
      let spaceBetween = slidesPerView.spaceBetween;
      slidesPerView = slidesPerView.slidesPerView;
      this.maxWidth =
        (document.querySelector(".recommended-products").getBoundingClientRect()
          .width -
          spaceBetween * slidesPerView) /
          slidesPerView +
        (window.innerWidth > 768 ? 2 : 0);
    },
    getSlidesPerView() {
      let c = Number.parseInt(window.innerWidth);
      let ok = 1;
      let max = -1;
      Object.keys(breakpoints).forEach((b) => {
        b = Number.parseInt(b);
        if (c > b && b > max) {
          ok = breakpoints[b];
          max = b;
        }
      });
      return ok;
    },
    initSlider(kill = false) {
      this.initSwiper("recommendedProductSlider", kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper(".recommended-products .swiper-container", {
          spaceBetween: 1,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".recommended-products .next-btn-holder",
            prevEl: ".recommended-products .prev-btn-holder",
          },
          breakpoints,
        });
        setTimeout(() => {
          this.minHeight = document
            .querySelector(".recommended-products .swiper-container")
            .getBoundingClientRect().height;
        }, 1700);
      });
    },
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
    getEnough(array) {
      return [...array].splice(0, Math.min(array.length, this.showCount));
    },
  },
};
</script>

<style scoped>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.recommended-products {
  background-color: #ff3c60;
  border-radius: 25px;
  padding: 0 40px 0 40px;
  position: relative;
  margin-bottom: 7rem;
  margin-top: 2rem;
  width: 90%;
}
.swiper-slide {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.recommended-content {
  position: relative;
  top: 70px;
}
.swiper-container {
  animation: fade 450ms;
}
.recommended-products-tabs {
  position: relative;
  top: 40px;
  right: 0;
}
.prev-btn-holder {
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateX(1px);
}
.next-btn-holder {
  position: absolute;
  top: 50%;
  left: 100%;
}
.prev-btn {
  background-color: #ff3c60;
  height: 140px;
  width: 120px;
  position: relative;
  -webkit-clip-path: polygon(
    93% 15%,
    100% 0,
    100% 50%,
    100% 100%,
    93% 85%,
    80% 70%,
    73% 60%,
    70% 50%,
    73% 40%,
    80% 30%
  );
  clip-path: polygon(
    93% 15%,
    100% 0,
    100% 50%,
    100% 100%,
    93% 85%,
    80% 70%,
    73% 60%,
    70% 50%,
    73% 40%,
    80% 30%
  );
}
.prev-btn-icon {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
}
.next-btn {
  background-color: #ff3c60;
  position: relative;
  height: 140px;
  width: 120px;
  -webkit-clip-path: polygon(
    7% 15%,
    0 0,
    0 50%,
    0 100%,
    7% 85%,
    20% 70%,
    27% 60%,
    30% 50%,
    27% 40%,
    20% 30%
  );
  clip-path: polygon(
    7% 15%,
    0 0,
    0 50%,
    0 100%,
    7% 85%,
    20% 70%,
    27% 60%,
    30% 50%,
    27% 40%,
    20% 30%
  );
}
.next-btn-icon {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
}

.seeMoreHomeLink {

  border-radius: 20px; border: 1px solid #fff; padding: 6px 16px 4px 16px; 
  font-size: 14px !important;
  transition: all .3s;
}
.seeMoreHomeLink:hover{
  color: #000 !important;
  background-color: #fff;
}
@media (max-width: 1199px) {
  .recommended-products {
    border-radius: 20px;
    padding: 0;
  }
  .recommended-content {
    position: relative;
    top: 80px;
    padding-inline: 20px;
  }
  .next-btn-holder {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateX(-1px);
  }
}
@media (max-width: 540px) {
  .recommended-products {
    border-radius: 0;
    padding: 0;
  }
}
</style>
